import { memo, useCallback } from 'react';
import styled from 'styled-components';
import { useShallow } from 'zustand/react/shallow';

import { InfinityScrollSpin } from 'shared/ui/infinityScrollSpin/InfinityScrollSpin';
import ChatListItemsEmpty from './components/ChatListItemsEmpty/ChatListItemsEmpty';
import { TTabs, useChatListState } from '../../state/useChatListState';
import ChatListItem from './components/ChatListItem/ChatListItem';
import { IChat } from 'interfaces';
import { useChatListItemTranslations } from './components/ChatListItem/hooks/useChatListItemTranslations';
import { useIconDefinitions } from './components/ChatListItem/hooks/useIconsDefinitions';

import { ChatListItemsStyled } from './assets/styles';

type TChatListItems = {
  items: IChat[];
  typeTabs: TTabs;
};

const ChatListItems = memo(({ items, typeTabs }: TChatListItems) => {
  const {
    isLoadingLastChat,
    setIsLoadingLastChat,

    getPartnersChatsList,
    getPhexChatsList,
    phexChatsTotalPages,
    chatListTotalPages,

    currentPage,
    setCurrentPage
  } = useChatListState(
    useShallow(state => ({
      isLoadingLastChat: state.isLoadingLastChat,
      setIsLoadingLastChat: state.setIsLoadingLastChat,

      getPartnersChatsList: state.getPartnersChatsList,
      getPhexChatsList: state.getPhexChatsList,

      phexChatsTotalPages: state.phexChatsTotalPages,
      chatListTotalPages: state.chatListTotalPages,

      currentPage: state.currentPage,
      setCurrentPage: state.setCurrentPage,
    }))
  )

  const loadMoreItems = useCallback(() => {
    setIsLoadingLastChat(true);
    setCurrentPage(currentPage + 1);

    if (typeTabs === 'personal') {
      void getPartnersChatsList().catch(e => {
        console.error('Failed to load personal chats', e)
      })
    } else {
      void getPhexChatsList().catch(e => {
        console.error('Failed to load corporate chats', e)
      })
    }
  }, [
    currentPage, typeTabs,
    setIsLoadingLastChat, setCurrentPage,
    getPartnersChatsList, getPhexChatsList
  ]);

  const translations = useChatListItemTranslations()
  const iconDefinitions = useIconDefinitions()

  const renderChatListItem = (item: IChat) => (
    <ChatListItem 
      key={item.id} 
      typeTabs={typeTabs} 
      item={item} 
      translations={translations} 
      iconDefinitions={iconDefinitions} 
    />
  );

  if (!items?.length) {
    return (
      <ChatListItemsStyled>
        <ChatListItemsEmpty />
      </ChatListItemsStyled>
    )
  }

  return (
    <ChatListItemsStyled>
      {items?.map(renderChatListItem)}

      <WrapperLoaderStyled>
        <InfinityScrollSpin
          isLoading={isLoadingLastChat}
          incrementPageCallback={loadMoreItems}
          total={
            typeTabs === 'personal'
              ? chatListTotalPages
              : phexChatsTotalPages
          }
          currentLengthList={items.length}
        />
      </WrapperLoaderStyled>
    </ChatListItemsStyled>
  )
});

export default ChatListItems;

const WrapperLoaderStyled = styled.div`
    height: 100px;
`;
