import { styled } from 'styled-components';
import { BasicTabs } from 'shared/ui/basic-tabs';

export const TabLabelStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ChatListFiltersStyled = styled.div`
  margin-bottom: 20px;
`;

export const TabsStyled = styled(BasicTabs)`
  &.ant-tabs {
    .ant-tabs-tab {
      padding-left: 0;
      padding-right: 0;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.8);

      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: #1677ff
        }
      }
    }
  }

  .ant-tabs-ink-bar {
    background-color: #1677ff;
  }

  .ant-badge-status-cyan {
    background: #0ec6ad;
  }
`;
