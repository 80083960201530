import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NotificationAsideContainerStyled = styled.div`
  position: absolute;
  top: 13px;
  right: -675%;
  background: #14141f;
  padding: 0 1px 1px 1px;
  height: calc(100vh - 82px);
  width: 500px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

export const NotificationAsideHeaderStyled = styled.div`
  background: #14141f;
  position: sticky;
  top: 0;
  padding: 32px 16px 0;
  display: flex;
  justify-content: space-between;
  min-height: 45px;
  height: 45px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

export const NotificationAsideSubHeaderStyled = styled.div`
  position: sticky;
  top: 78px;
  min-height: 94px;
  height: 94px;
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

export const NotificationAsideTitleStyled = styled.div`
  font-weight: 700;
  font-size: 24px;
  color: rgba(251, 251, 255, 1);
`;

export const NotificationAsideSettingsButtonStyled = styled(Link)`
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const NotificationAsideContentStyled = styled.div`
  position: sticky;
  top: 172px;
  overflow-y: auto;
  min-height: calc(100vh - 320px);
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #474755;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
`;

export const NotificationAsideFooterStyled = styled.div`
  position: sticky;
  bottom: 0;
  min-height: 56px;
  height: 56px;
  background-color: #14141f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`;

export const NotificationAsideLinkStyled = styled(Link)`
  color: white;
  cursor: pointer;
  &:hover {
    color: rgba(22, 119, 255, 1);
  }
`;
export const NotificationAsideButtonStyled = styled.div`
  color: white;
  cursor: pointer;
  &:hover {
    color: rgba(22, 119, 255, 1);
  }
`;
