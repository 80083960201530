import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalState } from '../../model/useModalState';
import { ModalKeys } from '../../model/enums';
import { notification } from 'antd';
import { CloseIcon, InfoCircle } from '../../../../../assets/img/icons/icons';
import { axios } from '../../../../exios';
import { EXCHANGE_MAP_API_URL } from '../../../../../packages/keycloak-client/constants';
import { Input } from 'antd';
import {
  AcceptButtonStyled,
  BackButtonStyled,
  ButtonAsLinkStyled,
  ButtonGroupStyled,
  CommentBlockStyled,
  CommentTitleStyled,
  ModalBodyStyled,
  ModalDescriptionStyled,
  ModalDescriptionWithSpaceStyled,
  ModalStyled,
  ModalTitleStyled,
} from './ModalCancelTransaction.styles';
import { Link, useNavigate } from 'react-router-dom';

const { TextArea } = Input;

export const ModalCancelTransaction = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isModalOpen = useModalState((state) =>
    state.isModalOpen(ModalKeys.ModalCancelTransaction)
  );

  const closeModal = useModalState((state) => state.closeModal);

  const modalOptions = useModalState(
    (state) => state.getModal(ModalKeys.ModalCancelTransaction).options
  );

  const handleCloseModal = () => {
    closeModal(ModalKeys.ModalCancelTransaction);
  };

  const [value, setValue] = useState('');

  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(e.target.value);
  };

  const handleChangeOfferRequestStatus = async () => {
    try {
      const response = await axios.put(
        `${EXCHANGE_MAP_API_URL}/partner/api/exchange-point-offer-requests/${modalOptions.id}/cancel`,
        {
          reason: value,
        }
      );

      if(modalOptions.updateCallback) {
        modalOptions.updateCallback(response.data.data.id, response.data.data.status);
      }

      notification.success({
        message: t('Сделка отменена'),
        icon: <InfoCircle />,
        closeIcon: <CloseIcon />,
        description: (
          <>
            {modalOptions.status !== 'accepted' && (
              <>
                {t('Сделка')}
                {' '}
                <ButtonAsLinkStyled onClick={handleNavigateToDeal} role='link'>{modalOptions.name}</ButtonAsLinkStyled>
                {' '}
                {t(
                  `на ${
                    !modalOptions.isBuyingCrypto ? 'покупку' : 'продажу'
                  } отменена`
                  , { currency: modalOptions.currency })}
              </>
            )}
            {modalOptions.status === 'accepted' && (
              <>
                {t('Сделка')}
                {' '}
                <ButtonAsLinkStyled onClick={handleNavigateToDeal} role='link'>{modalOptions.name}</ButtonAsLinkStyled>
                {' '}
                {t(
                  `на ${
                    !modalOptions.isBuyingCrypto ? 'покупку' : 'продажу'
                  } отменена`
                  , { currency: modalOptions.currency })}
                {' '}
                {t('Ваш рейтинг снижен точка')}
              </>
            )}
          </>
        ),
        style: {
          width: '360px',
        },
      });
      // updateStatus(response.data.data.id, response.data.data.status);
    } catch {
      console.error('An error occurred while updating the transaction status');
      // notification.error({
      //   message: 'Заготовка для уведомления об ошибке',
      //   style: { borderLeft: '6px solid #FF4D4F', width: '400px' },
      // });
    } finally {
      closeModal(ModalKeys.ModalCancelTransaction);
      modalOptions.refetch && modalOptions.refetch();
    }
  }

  const handleNavigateToDeal= () => {
    closeModal(ModalKeys.ModalCancelTransaction);
    navigate(`/deals/${modalOptions.id}`);
  }

  return (
    <ModalStyled
      open={isModalOpen}
      onCancel={handleCloseModal}
      footer={null}
      width={560}
      closable={true}
      centered
    >
      <ModalBodyStyled>
        <ModalTitleStyled>
          {t('Отменить сделку вопрос')}
        </ModalTitleStyled>
        {modalOptions.status === 'pending' && (
          <>
            <ModalDescriptionStyled>
              {t('В случае отмены запятая действия по сделке больше не будут доступны точка Данное')}
            </ModalDescriptionStyled>
            <CommentBlockStyled>
              <CommentTitleStyled>
                {t('Укажите причину отмены')}
              </CommentTitleStyled>
              <TextArea
                showCount
                maxLength={5000}
                onChange={onChangeHandler}
                placeholder={String(t('Введите комментарий'))}
                value={value}
              />
            </CommentBlockStyled>
          </>
        )}
        {(modalOptions.status === 'warranty_collateral' || modalOptions.status === 'ready') && (
          <ModalDescriptionWithSpaceStyled>
            {modalOptions.isBuyingCrypto ? t('Сделка на покупку') : t('Сделка на продажу')}
            {' '}
            <ButtonAsLinkStyled style={{cursor: 'pointer'}} onClick={handleNavigateToDeal} role="link">{modalOptions.name}</ButtonAsLinkStyled>
            {' '}
            {t('будет отменена точка Вы не сможете вернуть её в работу точка Данное действие негативно')}
          </ModalDescriptionWithSpaceStyled>
        )}
        {modalOptions.status === 'accepted' && (
          <>
            <ModalDescriptionStyled>
              {t('В случае отмены запятая действия по сделке больше не будут доступны точка Это негативно')}
            </ModalDescriptionStyled>
            <CommentBlockStyled>
              <CommentTitleStyled>
                {t('Укажите причину отмены')}
              </CommentTitleStyled>
              <TextArea
                showCount
                maxLength={5000}
                onChange={onChangeHandler}
                placeholder={String(t('Введите комментарий'))}
                value={value}
              />
            </CommentBlockStyled>
          </>
        )}
        <ButtonGroupStyled>
          <BackButtonStyled onClick={handleCloseModal}>
            {t('Назад')}
          </BackButtonStyled>
          <AcceptButtonStyled onClick={handleChangeOfferRequestStatus} type="primary">
            {t('Отменить сделку')}
          </AcceptButtonStyled>
        </ButtonGroupStyled>
      </ModalBodyStyled>
    </ModalStyled>
  );
};

