import { useNavigate } from 'react-router-dom';
import { FunctionComponent, memo } from 'react';

import { FINMS_URL } from 'packages/keycloak-client/constants';
import notClsx from 'shared/helpers/not-clsx';
import { TTabs, useChatListState } from 'pages/chat-list/state/useChatListState';
import { useWindowBreakpointState } from 'state/useWindowBreakpointState';
import { IChat } from 'interfaces';
import ChatListItemMessageCounter from './components/MessageCounter/ChatListItemMessageCounter';
import ChatListItemOfferStatus from './components/OfferStatus/ChatListItemOfferStatus';
import ChatListItemOfferStep from './components/OfferStep/ChatListItemOfferStep';
import ChatListItemTypePair from './components/TypePair/ChatListItemTypePair';
import { TChatListTranslations } from './hooks/useChatListItemTranslations';
import { TIconDefinitions } from './hooks/useIconsDefinitions';
import { ChatListItemClient, ChatListItemCompany } from './ui/ChatListItemParticipant';
import { DesktopLayout, MobileLayout } from './ui/layouts';

import { ChatListItemCard } from './ChatListItem.style';

type ChatListItemProps = {
  item: IChat;
  typeTabs: TTabs;
  translations: TChatListTranslations
  iconDefinitions: TIconDefinitions
};

const ChatListItem: FunctionComponent<ChatListItemProps> = memo(({ 
  item, 
  typeTabs,
  translations,
  iconDefinitions,
}) => {
  const navigate = useNavigate();

  const setOpenChatId = useChatListState((state) => state.setOpenChatId);
  const openChatId = useChatListState((state) => state.openChatId);
  const setIsOpenChat = useChatListState((state) => state.setIsOpenChat);

  const setChatToStore = () => {
    setOpenChatId(item.id);
    setIsOpenChat(true);
  };
  const handleOpenChat = () => {
    if (typeTabs === 'personal') {
      if (item.entity_type === 'offer_deal') {
        navigate(`/deals/${item.offer_deal_id}`);
      } else {
        setChatToStore();
      }
    } else {
      if (item.entity_type === 'offer_deal') {
        window.location.href = `${FINMS_URL}/offers/request/${item.offer_deal_id}`;
      } else {
        setChatToStore();
      }
    }
    // if (
    //   typeTabs === 'personal' ||
    //   (typeTabs === 'corporate' && item.entity_type === 'offer')
    // ) {
    //   setOpenChatId(item.id);
    //   setIsOpenChat(true);
    // } else if (typeTabs === 'corporate') {
    //   window.location.href = `${FINMS_URL}/offers/request/byOffer/${item.offer?.id}`;
    // }
  };

  const isDesktop = useWindowBreakpointState(state => state.computed.isScreen1280)

  const isBuyingCrypto = Boolean(item.offer?.is_buying_crypto)
  const isCorporate = typeTabs === 'corporate'
  const isBuyingOrder = isCorporate ? !isBuyingCrypto : isBuyingCrypto
  const cardClassName = notClsx(
    isBuyingOrder ? 'is-buying-order' : undefined,
    isCorporate && 'is-corporate-layout',
  )

  const elements = {
    pair: (
      <ChatListItemTypePair
        currentTabKey={typeTabs}
        isRefereeInChat={item.has_referee}
        pair={`${item.offer?.crypto_payment_system_currency?.currency_key}-${item.offer?.cash_currency_code}`}
        isSellingCrypto={item.offer?.is_buying_crypto}
        translations={translations}
      />
    ),
    company: (
      <ChatListItemCompany
        companyName={item.offer?.exchange_point?.name}
        metrics={item?.offer?.exchange_point?.metrics}
      />
    ),
    client: isCorporate ? (
      <ChatListItemClient
        clientNickname={(item?.offer_deal?.nickname || item?.client_nickname) ?? ''}
      />
    ) : null,
    offerStatus: (
      <ChatListItemOfferStatus
        isActiveStatus={'active' === item.offer?.status}
        translations={translations}
      />
    ),
    offerStep: (
      <ChatListItemOfferStep
        step={item.offer_deal?.status}
        id={item?.offer_deal?.name}
        closedAt={item?.closed_at}
        createdAt={item?.created_at}
        translations={translations}
        iconDefinitions={iconDefinitions}
      />
    ),
    counter: (
      <ChatListItemMessageCounter count={item?.unread_count} />
    )
  }

  return (
    <ChatListItemCard className={cardClassName} onClick={handleOpenChat} $isActive={item.id === openChatId}>
      {isDesktop ? (
        <DesktopLayout className={notClsx(isCorporate && 'is-corporate-layout')}>
          {elements.pair}
          {elements.company}
          {elements.client}
          {elements.offerStatus}
          {elements.offerStep}
          {elements.counter}
        </DesktopLayout>
      ) : (
        <MobileLayout elements={elements} />
      )}
    </ChatListItemCard>
  );
});

export default ChatListItem;
