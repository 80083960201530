import { Layout } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { styled } from 'styled-components';

import { Sidebar } from './components/sidebar';
import { CustomHeader } from './components/header';
import { ROUTER_PATH } from '../../../router/path';
import { useAuthState } from '../../../state/useAuthState';
import { useCookiesCustom } from '../../../hooks/useCookieCustom';
import { useChatTokensState } from '../../../state/useChatTokensState';
import { useChatListState } from '../../../pages/chat-list/state/useChatListState';
import { useChatAsideState } from 'widgets/chatAside/model/useChatAsideState';
import { MEDIA_BREAKPOINT } from 'shared/constants/media-breakpoints';
import notClsx from 'shared/helpers/not-clsx';

import './index.css';

const { Content } = Layout;

const AppLayout = (): JSX.Element => {
  const adminToken = useParams()?.adminToken;
  const location = useLocation();
  const navigate = useNavigate();
  const { cookie, setCookie } = useCookiesCustom();
  const serverTime = useAuthState((state) => state.serverTime);
  const getUserKC = useAuthState((state) => state.getUserKC);
  const userAPI = useAuthState((state) => state.userAPI);
  const searchParams = new URLSearchParams(window.location.search);
  const [resetTimestamp, setResetTimestamp] = useState<number | null>(null);
  const token = searchParams.get('token');
  const [isChat, setIsChat] = useState(false);
  const { t, i18n } = useTranslation();

  const getPhexChatToken = useChatTokensState(
    (state) => state.getPhexChatToken
  );
  const getPartnerChatToken = useChatTokensState(
    (state) => state.getPartnerChatToken
  );
  const getHubChatToken = useChatTokensState((state) => state.getHubChatToken);
  const getPartnersChatsList = useChatListState(
    (state) => state.getPartnersChatsList
  );
  const getPhexChatsList = useChatListState((state) => state.getPhexChatsList);

  const getChatCounts = useChatAsideState((state) => state.getChatCounts);

  const refreshToken = useAuthState((state) => state.refreshToken);

  const currentServerTimeFromCookie = cookie['currentServerTime']
    ? Number(cookie['currentServerTime'])
    : 0;

  const [currentServerTime, setCurrentServerTime] = useState<number>(
    currentServerTimeFromCookie || serverTime
  );

  // const setIsExitUser = (value: boolean) => {
  //     //Записываем в куки, что пользователь вышел из браузера
  //     setCookie('isExitUser', value);
  // };

  useEffect(() => {
    if (token) {
      // Если есть токен, то пускать
      return;
    }

    if (!cookie['token']) {
      console.info('No userKC in localStorage, redirect to login');
      getUserKC({ cookie, setCookie });
      navigate(ROUTER_PATH.FormsPages.LOGIN);
    }
  }, [cookie.token]);

  useEffect(() => {
    i18n.changeLanguage(cookie['i18nLang']);
  }, [cookie['i18nLang']]);

  useEffect(() => {
    const savedTimestamp = cookie.refreshTokenTimestamp;

    if (parseInt(savedTimestamp) > 0) {
      setResetTimestamp(parseInt(savedTimestamp));
    }

    const handleBeforeUnload = () => {
      const ResetTimestamp = resetTimestamp ? resetTimestamp.toString() : '0';
      const CurrentServerTime = currentServerTime.toString();

      setCookie('refreshTokenTimestamp', ResetTimestamp);
      setCookie('currentServerTime', CurrentServerTime);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [
    resetTimestamp,
    cookie.refreshTokenTimestamp,
    serverTime,
    currentServerTime,
  ]);

  useEffect(() => {
    setIsChat(location.pathname.startsWith('/chat'));
  }, [location.pathname]);

  useEffect(() => {
    const updateChatTokenWorker = new Worker(
      new URL('../../../workers/chatRefreshWorker', import.meta.url)
    );
    const accessRefreshWorker = new Worker(
      new URL('../../../workers/accessRefreshWorker', import.meta.url)
    );

    getPhexChatToken().then(() => {
      getPhexChatsList().then();
    });
    getPartnerChatToken().then(() => {
      getPartnersChatsList().then();
    });

    if (adminToken) {
      getHubChatToken().then();
    }

    accessRefreshWorker.onmessage = () => {
      if (cookie['token']) {
        refreshToken({}).then();
      }
    };

    updateChatTokenWorker.onmessage = () => {
      if (cookie['phexChatToken'] !== 'undefined') {
        getPhexChatToken().then(() => {
          getPhexChatsList().then();
        });
      }
      if (cookie['partnerChatToken'] !== 'undefined') {
        getPartnerChatToken().then(() => {
          getPartnersChatsList().then();
        });
      }
      if (cookie['hubChatToken'] !== 'undefined') {
        getHubChatToken().then();
      }
    };

    return () => {
      updateChatTokenWorker.terminate();
      accessRefreshWorker.terminate();
    };
  }, []);

  const contentClassName = notClsx(isChat && 'is-chat-page')

  return (
    <Layout hasSider>
      <Sidebar />
      <Layout
        style={{
          backgroundColor: '#14141F',
        }}
      >
        <CustomHeader />
        <ContentStyled className={contentClassName}>
          <Outlet />
        </ContentStyled>
      </Layout>
    </Layout>
  );
};

export default AppLayout;

const ContentStyled = styled(Content)`
  padding: 0 16px;
  min-height: calc(100vh - 80px);
  margin-left: 80px;

  @media ${MEDIA_BREAKPOINT.MD} {
    padding: 0 24px;
  }

  @media ${MEDIA_BREAKPOINT.LG} {
    margin-left: 0;
  }

  @media ${MEDIA_BREAKPOINT.XL} {
    padding: 0 32px;
  }

  &.is-chat-page {
    padding: 0;
  }
`;
