import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ChatListWrapper,
  EmptyText,
  ChatEmptyStyled,
  ScrollSpinWrapper,
} from './ChatAsideList.style';
import ChatAsideListItem from './ChatAsideListItem/ChatAsideListItem';
import NoChatImg from '../../../../assets/img/icons/noChats.png';
import { InfinityScrollSpin } from '../../../../shared/ui/infinityScrollSpin/InfinityScrollSpin';
import { IChat } from '../../../../interfaces';
import { useChatAsideState } from '../../model/useChatAsideState';

type ChatAsideListProps = {
  chats: IChat[];
  totalChats: number;
};

export const ChatAsideList = ({ chats, totalChats }: ChatAsideListProps) => {
  const { t } = useTranslation();

  const fetchChats = useChatAsideState((state) => state.fetchChats);

  const isLoadingChats = useChatAsideState((state) => state.isLoadingChats);

  if (isLoadingChats || chats.length) {
    return (
      <ChatListWrapper>
        {chats.map((chat) => {
          return <ChatAsideListItem key={chat.id} chat={chat} />;
        })}
        <ScrollSpinWrapper>
          <InfinityScrollSpin
            isLoading={isLoadingChats}
            incrementPageCallback={fetchChats}
            total={totalChats}
            currentLengthList={chats.length}
          />
        </ScrollSpinWrapper>
      </ChatListWrapper>
    );
  }

  return (
    <ChatEmptyStyled>
      <img src={NoChatImg} alt={'emptyChatsIcon'} />
      <EmptyText strong>{t('У вас нет чатов')}</EmptyText>
    </ChatEmptyStyled>
  );
};
