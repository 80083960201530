import { useEffect } from 'react';

export const useWindowScrollLock = (isLocked: boolean) => {
  useEffect(() => {
    const handleScrollLock = () => {
      if (isLocked) {
        const scrollY = window.scrollY;
        document.body.style.position = 'fixed';
        document.body.style.top = `-${scrollY}px`;
        document.body.style.width = '100%';
      } else {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
    };
    handleScrollLock();

    return () => {
      document.body.style.position = '';
      document.body.style.top = '';
    };
  }, [isLocked]);
};
