import {devtools} from 'zustand/middleware';
import create from 'zustand';
import {produce} from 'immer';
import {axios} from '../../../shared/exios';
import {EXCHANGE_MAP_API_URL, FINMS_URL_API,} from '../../../packages/keycloak-client/constants';
import {IChat} from '../../../interfaces';
import {useMessagesState} from "../../../components/ChatModule/states/useMessagesState";
import {calcAuthToken} from "../../../components/ChatModule/helpers/calcAuthToken";

export type TTabs = 'personal' | 'corporate';

type TChatListState = {
    currentChat: null | any;
    phexChatsTotalPages: number;
    chatListTotalPages: number;
    isOpenChat: boolean;
    openChatId: null | number;
    phexChatsList: IChat[];
    partnersChatsList: IChat[];
    setIsOpenChat: (value: boolean) => void;
    setOpenChatId: (value: number | null) => void;
    getPartnersChatsList: () => Promise<any>;
    getPhexChatsList: () => Promise<any>;
    setChatListTotalPages: (value: number) => void;
    resetFields: () => void;
    isLoadingSkeleton: boolean;
    isLoadingLastChat: boolean;
    setIsLoadingSkeleton: (value: boolean) => void;
    setIsLoadingLastChat: (value: boolean) => void;

    currentPage: number;
    setCurrentPage: (value: number) => void;

    deletePhexChatsList: () => void;
    deletePartnersChatsList: () => void;
    typeTabs: TTabs;
    setTypeTabs: (value: string) => void;
    setCurrentChat: () => void;
};

export const useChatListState = create<TChatListState>()(
    devtools(
        (set, get) => ({
            phexChatsTotalPages: 0,
            chatListTotalPages: 0,
            isOpenChat: false,
            openChatId: null,
            partnersChatsList: [],
            phexChatsList: [],
            isLoadingSkeleton: true,
            isLoadingLastChat: false,
            currentPage: 1,
            typeTabs: 'personal',
            currentChat: null,

            setCurrentChat: () => {
                set(
                    produce((draft) => {
                        const typeTabs = draft.typeTabs;
                        useChatListState?.getState().setIsOpenChat(false);
                        useMessagesState?.getState().setIsFetchingMessages(true);
                        useMessagesState.getState().clearMessages();
                        if (typeTabs === 'personal') {
                            const currentChat = draft.partnersChatsList.find((chat: IChat) => chat?.id === draft.openChatId);
                            draft.currentChat = currentChat;
                            useMessagesState.getState().searchMessages({
                                entity_type: currentChat?.entity_type,
                                entity_id: String(currentChat?.entity_type === 'offer' ? currentChat?.offer_id : currentChat?.offer_deal?.id),
                                client_uid: currentChat?.client_uid,
                                auth_token: calcAuthToken({chatType: 'list', typeTabs})
                            }).then()
                        } else {
                            const currentChat = draft.phexChatsList.find((chat: IChat) => chat?.id === draft.openChatId)
                            draft.currentChat = currentChat;
                            useMessagesState.getState().searchMessages({
                                entity_type: currentChat?.entity_type,
                                entity_id: String(currentChat?.entity_type === 'offer' ? currentChat?.offer_id : currentChat?.offer_deal?.id),
                                client_uid: currentChat?.client_uid,
                                auth_token: calcAuthToken({chatType: 'list', typeTabs})
                            }).then();
                        }
                    })
                );
            },

            setTypeTabs: (value: string) => {
                set(
                    produce((draft) => {
                        draft.typeTabs = value;
                    })
                );
            },

            deletePartnersChatsList: () => {
                set(
                    produce((draft) => {
                        draft.partnersChatsList = [];
                    })
                );
            },

            deletePhexChatsList: () => {
                set(
                    produce((draft) => {
                        draft.phexChatsList = [];
                    })
                );
            },

            setCurrentPage: (value) => {
                set(
                    produce((draft) => {
                        draft.currentPage = value;
                    })
                );
            },

            resetFields: () => {
                set(
                    produce((draft) => {
                        draft.phexChatsTotalPages = 0;
                        draft.chatListTotalPages = 0;
                        draft.isOpenChat = false;
                        draft.openChatId = null;
                        draft.partnersChatsList = [];
                        draft.phexChatsList = [];
                        draft.isLoadingSkeleton = true;
                        draft.IsLoadingLastChat = false;
                    })
                );
            },
            setIsLoadingLastChat: (value) => {
                set(
                    produce((draft) => {
                        draft.isLoadingLastChat = value;
                    })
                );
            },
            setIsLoadingSkeleton: (value) => {
                set(
                    produce((draft) => {
                        draft.isLoadingSkeleton = value;
                    })
                );
            },
            setChatListTotalPages: (value: number) => {
                set(
                    produce((draft) => {
                        draft.chatListTotalPages = value;
                    })
                );
            },
            setOpenChatId: (value: number | null) => {
                set(
                    produce((draft) => {
                        draft.openChatId = value;
                    }),
                    false,
                    {
                        type: 'useChatListState => setStep',
                    }
                );
            },
            setIsOpenChat: (value: boolean) => {
                set(
                    produce((draft) => {
                        draft.isOpenChat = value;
                    }),
                    false,
                    {
                        type: 'useChatListState => setStep',
                    }
                );
            },
            getPartnersChatsList: async () => {
                try {
                    const res = await axios.post(
                        `${EXCHANGE_MAP_API_URL}/partner/api/chats/search`,
                        {
                            page: get().currentPage,
                            limit: 10,
                            includes: [
                                {
                                    relation: 'offer',
                                },
                                {
                                    relation: 'offer_deal',
                                },
                                {relation: 'offer.exchange_point.company'},
                                {relation: 'offer.exchange_point.metrics'},
                                {relation: 'offer.cash_payment_system_currency'},
                                {relation: 'offer.crypto_payment_system_currency'},
                            ],
                        }
                    );
                    const arr = res?.data?.data;

                    set(
                        produce((draft) => {
                            const uniqueArray = Array.from(
                                new Map(
                                    [...draft.partnersChatsList, ...arr].map((item) => [
                                        item.id,
                                        item,
                                    ])
                                ).values()
                            );

                            draft.partnersChatsList = uniqueArray;
                            draft.chatListTotalPages = res?.data?.meta?.total;
                        })
                    );
                } catch (e) {
                    console.error('getPartnersChatsList error:', e);
                } finally {
                    set(
                        produce((draft) => {
                            draft.isLoadingSkeleton = false;
                            draft.isLoadingLastChat = false;
                        })
                    );
                }
            },
            getPhexChatsList: async () => {
                try {
                    const res = await axios.post(
                        `${FINMS_URL_API}/server/api/chats/search`,
                        {
                            page: get().currentPage,
                            includes: [
                                {
                                    relation: 'offer',
                                },
                                {
                                    relation: 'offer_deal',
                                },
                                {relation: 'offer.exchange_point.company'},
                                {relation: 'offer.exchange_point.metrics'},
                                {relation: 'offer.cash_payment_system_currency'},
                                {relation: 'offer.crypto_payment_system_currency'},
                            ],
                        }
                    );
                    const arr = res?.data?.data;
                    set(
                        produce((draft) => {
                            const uniqueArray = Array.from(
                                new Map(
                                    [...draft.phexChatsList, ...arr].map((item) => [
                                        item.id,
                                        item,
                                    ])
                                ).values()
                            );

                            draft.phexChatsList = uniqueArray;
                            draft.phexChatsTotalPages = res?.data?.meta?.total;
                        })
                    );
                } catch (e) {
                    console.error('getPhexChatsList error:', e);
                } finally {
                    set(
                        produce((draft) => {
                            draft.isLoadingSkeleton = false;
                            draft.isLoadingLastChat = false;
                        })
                    );
                }
            },
            setPhexChatsTotalPages: (value: number) => {
                set(
                    produce((draft) => {
                        draft.phexChatsTotalPages = value;
                    })
                );
            },
        }),
        {
            anonymousActionType: 'useChatListState action',
            name: 'useChatListState',
        }
    )
);
