import ChatModule from '../ChatModule/ChatModule';
import {useChatListState} from '../../pages/chat-list/state/useChatListState';
import {useMessagesState} from '../ChatModule/states/useMessagesState';
import {useEffect, useLayoutEffect, useState} from 'react';
import {useChatTokensState} from '../../state/useChatTokensState';
import {calcAuthToken} from '../ChatModule/helpers/calcAuthToken';
import {centrifuge, subscribe} from '../../workers/chatWorker';
import {Subscription} from "centrifuge";

const ChatListWrapper = () => {
    const currentChat = useChatListState((state) => state.currentChat);
    const getChatInfo = useMessagesState((state) => state.getChatInfo);
    const setIsOpenChat = useChatListState((state) => state.setIsOpenChat);
    const chatInfo = useMessagesState((state) => state.chatInfo);
    const opponent = chatInfo?.participants?.find((participant) => !!participant?.is_opponent);
    const searchMessages = useMessagesState((state) => state.searchMessages);
    const totalMessages = useMessagesState((state) => state.totalMessages);
    const currentPage = useMessagesState((state) => state.currentPage);
    const isFetchingMessages = useMessagesState((state) => state.isFetchingMessages);
    const messageLimit = useMessagesState((state) => state.messageLimit);
    const setIsFetchingMessages = useMessagesState((state) => state.setIsFetchingMessages);
    const addMessageToMessages = useMessagesState((state) => state.addMessageToMessages);
    const messages = useMessagesState((state) => state.messages);
    const wssChannels = useChatTokensState((state) => state.wssChannels);
    const getWSChatToken = useChatTokensState((state) => state.getWSChatToken);
    const openChatId = useChatListState((state) => state.openChatId);
    const createAttachment = useMessagesState((state) => state.createAttachment);
    const addMessage = useMessagesState((state) => state.addMessage);
    const changeIsUploadInAttachment = useMessagesState((state) => state.changeIsUploadInAttachment);
    const uploadAttachmentToMinio = useMessagesState((state) => state.uploadAttachmentToMinio);
    const typeTabs = useChatListState((state) => state.typeTabs);
    const addAttachmentToLastMessage = useMessagesState((state) => state.addAttachmentToLastMessage);
    const [hasReferee, setHasReferee] = useState(false);

    const calcEntityId = () => {
        return currentChat?.entity_type === 'offer' ? currentChat?.offer_id : currentChat?.offer_deal_id
    }

    useLayoutEffect(() => {
        getWSChatToken().then()
        if (currentChat?.entity_type && currentChat?.client_uid && currentChat?.entity_type) {
            getChatInfo({
                entity_type: currentChat?.entity_type,
                entity_id: calcEntityId(),
                client_uid: currentChat?.client_uid,
                auth_token: calcAuthToken({chatType: 'list', typeTabs}),
            }).then()
        }
    }, [currentChat?.entity_type, currentChat?.entity_id, currentChat?.client_uid, openChatId])

    useEffect(() => {
        if (chatInfo) {
            setHasReferee(chatInfo?.has_referee)
        }
    }, [chatInfo?.has_referee])

    useEffect(() => {
        const subscriptions: (Subscription | undefined)[] = [];
        const connectToChannels = async () => {
            if (wssChannels.length) {
                for (const channelName of wssChannels) {
                    const subscription = await subscribe({
                        channelName,
                        entityId: calcEntityId(),
                        hasReferee,
                        addMessageToMessages,
                        addAttachmentToLastMessage
                    })
                    // @ts-ignore
                    subscriptions.push(subscription);
                }
            }
        };
        connectToChannels().then()

        return () => {
            subscriptions?.forEach((subscription) => {
                subscription?.unsubscribe();
            });
        };
    }, [wssChannels, hasReferee, currentChat, centrifuge])

    useEffect(() => {
        if ((messageLimit * currentPage < totalMessages || currentPage === 1)) {
            if (currentChat) {
                searchMessages({
                    entity_type: currentChat?.entity_type,
                    entity_id: calcEntityId(),
                    client_uid: currentChat?.client_uid,
                    auth_token: calcAuthToken({chatType: 'list', typeTabs})
                }).then()
            }
        }
    }, [currentPage, totalMessages, messageLimit, openChatId, messages?.length]);

    useEffect(() => {
        if (centrifuge) {
            centrifuge?.connect();
        }
    }, [centrifuge])

    return (
        <ChatModule
            isShowTimer={true}
            isClosableChat={true}
            currentChat={currentChat}
            isReferee={chatInfo?.has_referee}
            setIsOpenChat={setIsOpenChat}
            opponent={opponent}
            setIsFetchingMessages={setIsFetchingMessages}
            searchMessages={searchMessages}
            isAdmin={false}
            isFetchingMessages={isFetchingMessages}
            messages={messages}
            addMessage={addMessage}
            changeIsUploadInAttachment={changeIsUploadInAttachment}
            createAttachment={createAttachment}
            uploadAttachmentToMinio={uploadAttachmentToMinio}
            calcEntityId={calcEntityId}
            entity_type={currentChat?.entity_type}
            client_uid={currentChat?.client_uid}
            typeTabs={typeTabs}
            chatType={'list'}
        />
    )
}

export default ChatListWrapper;
