import { CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import {
  ChatListHeaderStyled,
  ChatListHeaderTitleStyled,
  CloseButton,
} from './assets/styles';

type TChatListHeaderProps = {
  onCrossClick: () => void;
};

const ChatListHeader = ({ onCrossClick }: TChatListHeaderProps) => {
  const { t } = useTranslation();

  return (
    <ChatListHeaderStyled>
      <ChatListHeaderTitleStyled>{t(`Чаты`)}</ChatListHeaderTitleStyled>
      <CloseButton
        type={'text'}
        icon={<CloseOutlined />}
        onClick={onCrossClick}
      />
    </ChatListHeaderStyled>
  );
};

export default ChatListHeader;
