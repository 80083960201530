import { axios } from '../../../shared/exios';
import {
  EXCHANGE_MAP_API_URL,
  FINMS_URL_API,
} from '../../../packages/keycloak-client/constants';
import { produce } from 'immer';
import { IChat, IResponseShell } from '../../../interfaces';

const PAGE_LIMIT = 10;

export const getChats = async (
  type: 'personal' | 'corporate',
  page: number
) => {
  const URL =
    type === 'personal'
      ? `${EXCHANGE_MAP_API_URL}/partner/api/chats/search`
      : `${FINMS_URL_API}/server/api/chats/search`;
  try {
    const { data: res } = await axios.post<IResponseShell<IChat[]>>(URL, {
      page,
      limit: PAGE_LIMIT,
      includes: [
        {
          relation: 'offer',
        },
        {
          relation: 'offer_deal',
        },
        { relation: 'offer.exchange_point.company' },
        { relation: 'offer.exchange_point.metrics' },
        { relation: 'offer.cash_payment_system_currency' },
        { relation: 'offer.crypto_payment_system_currency' },
      ],
    });
    return { data: res.data, totalPages: res.meta.total };
  } catch (e) {
    console.error('getPartnerChats error:', e);
  }
};
