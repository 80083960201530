import Sider from 'antd/es/layout/Sider';
import styled, { css } from 'styled-components';

const SIDEBAR_COMPONENTS = {
  CustomSider: styled(Sider)<{
    $isScreen991: boolean
  }>`
    height: 100vh;
    position: sticky !important;
    left: 0;
    top: 0;
    bottom: 0;
    overflow: auto;
    ${({ $isScreen991 }) =>
      !$isScreen991 &&
      css`
        position: fixed !important;
        z-index: 1000;
    `}
  `,
};

export { SIDEBAR_COMPONENTS };
