import { CSSProperties, FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Statistic } from 'antd';
import styled from 'styled-components';

import notClsx from 'shared/helpers/not-clsx';

const { Countdown } = Statistic

interface IProps {
  className?: string
  style?: CSSProperties
  deadline: string | number | undefined;
  isHideMinutes?: boolean
  isOff?: boolean;
}

const GenericTimer: FunctionComponent<IProps> = ({ 
  className,
  style,
  deadline, 
  isHideMinutes, 
  isOff,
}) => {
  const { t } = useTranslation();
  
  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(() => {
    const givenDate = new Date(deadline ?? Date.now());
    const currentDate = new Date();

    setIsDisabled((currentDate >= givenDate) || !!isOff) 
  }, [deadline, isOff])

  const countdownClassName = notClsx(
    isDisabled && 'disabled',
    className
  )

  let template = isHideMinutes ? '-- : --' : '-- : -- : --'

  if (!isOff) {
    if (!isHideMinutes) {
      template = `DD ${t('д')} : HH ${t('ч')} : mm ${t('м')}`
    } else {
      template = `DD ${t('д')} : HH ${t('ч')}`
    }
  }

  return (
    <GenericTimerStyled
      className={countdownClassName}
      value={deadline}
      format={template}
      style={style}
      onFinish={() => setIsDisabled(true)}
    />
  );
};

export default GenericTimer;

const GenericTimerStyled = styled(Countdown)`
  &.ant-statistic .ant-statistic-content {
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    font-family: inherit;
    white-space: nowrap;
  }
`
