import { useCallback, type FunctionComponent, type ReactNode } from 'react';
import { Badge, TabsProps } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { ChatListFiltersStyled, TabLabelStyled, TabsStyled, } from './assets/styles';
import { TTabs, useChatListState } from '../../state/useChatListState';
import { CorporateIcon } from 'assets/img/icons/icons';
import { useChatAsideState } from 'widgets/chatAside/model/useChatAsideState';

type TRenderTabLabel = {
  label: string
  count: number
  icon?: ReactNode
  onClick: () => void
};

const ChatListTabLabel: FunctionComponent<TRenderTabLabel> = ({ label, count, onClick, icon }) => {
  return (
    <TabLabelStyled onClick={onClick}>
      {icon}
      {label}
      <Badge color="cyan" count={count} showZero={true} />
    </TabLabelStyled>
  )
}

const ChatListFilters = ({
  typeTabs,
  setTypeTabs,
}: {
  typeTabs: TTabs;
  setTypeTabs: (value: TTabs) => void;
}) => {
  const resetFields = useChatListState((state) => state.resetFields);
  const getPartnersChatsList = useChatListState(
    (state) => state.getPartnersChatsList
  );
  const getPhexChatsList = useChatListState((state) => state.getPhexChatsList);
  // const phexChatsTotalPages = useChatListState(
  //   (state) => state.phexChatsTotalPages
  // );
  // const chatListTotalPages = useChatListState(
  //   (state) => state.chatListTotalPages
  // );
  const setCurrentPage = useChatListState((state) => state.setCurrentPage);
  const deletePartnersChatsList = useChatListState(
    (state) => state.deletePartnersChatsList
  );
  const deletePhexChatsList = useChatListState(
    (state) => state.deletePhexChatsList
  );
  const chatCounts = useChatAsideState((state) => state.chatCounts);
  // const userFinmsApi = useAuthState((state) => state.userFinmsApi);

  const { t } = useTranslation();

  const onPersonalTabClick = useCallback(() => {
    resetFields();
    setCurrentPage(1);

    deletePhexChatsList();
    getPartnersChatsList();
  }, [resetFields, setCurrentPage, deletePhexChatsList, getPartnersChatsList])

  const onCorporateTabClick = useCallback(() => {
    resetFields();
    setCurrentPage(1);

    deletePartnersChatsList();
    getPhexChatsList();
  }, [resetFields, setCurrentPage, deletePartnersChatsList, getPhexChatsList])

  const tabs: TabsProps['items'] = [
    {
      label: (
        <ChatListTabLabel
          label={t('Личные')}
          count={chatCounts.personal}
          onClick={onPersonalTabClick}
          icon={<UserOutlined style={{ margin: 0 }} />}
        />
      ),
      key: 'personal',
    },
    {
      label: (
        <ChatListTabLabel
          label={t('Корпоративные')}
          count={chatCounts.corporate}
          onClick={onCorporateTabClick}
          icon={<CorporateIcon />}
        />
      ),
      key: 'corporate',
    },
  ];

  return (
    <ChatListFiltersStyled className='chat-list-tabs'>
      <TabsStyled
        defaultActiveKey={typeTabs}
        items={tabs}
        onChange={(val) => setTypeTabs(val as TTabs)}
      />
      {/*// TODO: Добавить фильтры*/}
    </ChatListFiltersStyled>
  );
};

export default ChatListFilters;
